import type { AxiosPromise } from 'axios'
// Local
import axios from '@/services/axios'

export const apiSettings = new (class Api {
  fetchRoles(): AxiosPromise {
    return axios({
      url: '/users/roles',
      method: 'get',
    })
  }
  getLinkToUploadSettingItem(
    data: any
  ): AxiosPromise<{ presignedUrl: string; uploadId: string }> {
    return axios({
      url: '/settings/get-link',
      method: 'put',
      data,
    })
  }
  uploadMediaForSettingItem(id: string) {
    return axios({
      url: `/settings/finish-upload/${id}`,
      method: 'put',
    })
  }
  getSettingItem(key: string) {
    return axios({
      url: `/settings/${key}`,
      method: 'get',
    })
  }
})()
