import type { AxiosPromise } from 'axios'

import axios from '@/services/axios'
import type { Client } from './namespace'

export const apiClients = new (class Api {
  fetchItems(params: Client.ReqFetchItems): AxiosPromise<Client.ReqFetchItems> {
    return axios({
      url: '/clients',
      method: 'get',
      params,
    })
  }

  fetchDetail(id: number): AxiosPromise<Client.ReqFetchItems> {
    return axios({
      url: `/clients/${id}`,
      method: 'get',
    })
  }

  fetchClientPoint(userId: number): AxiosPromise {
    return axios({
      url: `/referral/points/${userId}`,
      method: 'get',
    })
  }

  fetchClientPointsHistory(userId: number): AxiosPromise {
    return axios({
      url: `/referral/points/${userId}/history`,
      method: 'get',
    })
  }

  fetchAllClientPointsHistory(): AxiosPromise<Client.ResPointsHistoryItem> {
    return axios({
      url: `/referral/points/history`,
      method: 'get',
    })
  }

  addClientPoints(userId: number, data: Client.ReqAddPoints): AxiosPromise {
    return axios({
      url: `/referral/points/${userId}`,
      method: 'post',
      data,
    })
  }

  subtractClientPoints(
    userId: number,
    params: Client.ReqSubtractPoints
  ): AxiosPromise {
    return axios({
      url: `/referral/points/${userId}`,
      method: 'delete',
      params,
    })
  }

  create(data: Client.ReqCreate): AxiosPromise<Client.Detail> {
    console.log(data)
    return axios({
      url: '/clients',
      method: 'post',
      data,
    })
  }

  change({
    id,
    data,
  }: Client.ReqChange | Client.ReqChangeLink): AxiosPromise<Client.Detail> {
    return axios({
      url: `/clients/${id}`,
      method: 'patch',
      data,
    })
  }

  confirm(id: number): AxiosPromise<Client.Detail> {
    return axios({
      url: `/clients/${id}/send`,
      method: 'post',
    })
  }

  canConfirm(id: number, throwError?: boolean): AxiosPromise<boolean> {
    return axios({
      url: `/clients/${id}/is-ready-to-confirm`,
      method: 'post',
      params: { throwError },
    })
  }

  createNotification(data: Client.ReqPushMessage): AxiosPromise {
    return axios({
      url: '/notifications',
      method: 'post',
      data,
    })
  }

  sendNotificationToClients(data: any): AxiosPromise {
    return axios({
      url: '/notifications/to-clients',
      method: 'post',
      data,
    })
  }

  fetchRecoveryCode(id: number): AxiosPromise {
    return axios({
      url: `/auth/${id}/recovery-password`,
    })
  }
  changeJaw({ clientId, data }: Client.ReqChangeJaw): AxiosPromise {
    return axios({
      url: `/clients/${clientId}`,
      method: 'patch',
      data,
    })
  }

  deleteClient(clientId: number): AxiosPromise {
    return axios({
      url: `/clients/${clientId}`,
      method: 'delete',
    })
  }

  fetchClientPurchaseList(clientId: number, params: any): AxiosPromise {
    return axios({
      url: `/clients-purchase`,
      params: {
        ...params,
        clientId,
      },
    })
  }

  fetchClientPurchase(purchaseId: number): AxiosPromise {
    return axios({
      url: `/clients-purchase/${purchaseId}`,
    })
  }

  pubPassportData(userId: number, data: any) {
    return axios({
      url: `/clients-passport-data/${userId}`,
      method: 'put',
      data,
    })
  }

  putIllness(userId: number, data: any) {
    return axios({
      url: `/clients-illness/${userId}`,
      method: 'put',
      data,
    })
  }

  fetchClientProductionsHistory(
    userId: number
  ): AxiosPromise<Client.ProductionsHistoryItem[]> {
    return axios({
      url: `/productions/client-productions/${userId}`,
      method: 'get',
    })
  }

  addProduction(
    data: Client.ReqAddProduction
  ): AxiosPromise<Client.ProductionsHistoryItem> {
    return axios({
      url: 'productions',
      method: 'post',
      data,
    })
  }

  deleteProduction(productionId: number): AxiosPromise {
    return axios({
      url: `/productions/${productionId}`,
      method: 'delete',
    })
  }

  getAvatarPresignedUrl(params: {
    filename: string
    userId: number
    mimetype: string
  }) {
    return axios({
      url: `/clients/avatars/presigned-put-avatar`,
      params,
    })
  }

  finishUploadAvatar(id: string) {
    return axios.post(`clients/avatars/presigned-urls/${id}`)
  }
})()
