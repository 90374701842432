import { apiAuth } from '@/bus/auth/api'
import { apiClients } from '@/bus/clients/api'
import { apiFiles } from '@/bus/files/api'
import { apiProducts } from '@/bus/products/api'
import Axios, { AxiosResponse } from 'axios'

interface Params {
  filename: string
  mimetype: string
  userId?: number
  tagId?: number
  updateFileId?: number
}

interface AvatarParams {
  filename: string
  mimetype: string
  userId?: number
}

interface ProductTemplateParams {
  filename: string
  mimetype: string
  productId: number
  title: string
}

interface GetPresignedUrlReqResponse {
  presignedUrl: string
  uploadId: string
}

type GetPresignedUrlReq = (
  params: any
) => Promise<AxiosResponse<GetPresignedUrlReqResponse>>

class PresignedUploaderService {
  public async upload(
    file: any,
    getUrlReq: GetPresignedUrlReq,
    params: Params,
    onProgress?: (p: any) => void
  ): Promise<any> {
    const { presignedUrl, uploadId } = await this.getLink(getUrlReq, params)

    await this.uploadFile(presignedUrl, file, onProgress)
    return await this.endUpload(uploadId)
  }

  private async getLink(getUrlReq: GetPresignedUrlReq, params: Params) {
    const { data } = await getUrlReq(params)

    return data
  }

  public async uploadFile(url: string, file: any, onProgress: any) {
    await Axios.put(url, file, {
      onUploadProgress: onProgress,
    })
  }

  private async endUpload(id) {
    const { data } = await apiFiles.finishUpload(id)
    return data
  }

  public async uploadAvatar(
    file: any,
    getUrlReq: GetPresignedUrlReq,
    params: AvatarParams,
    onProgress?: (p: any) => void
  ): Promise<any> {
    const { presignedUrl, uploadId } = await this.getLink(getUrlReq, params)

    await this.uploadFile(presignedUrl, file, onProgress)

    if (params.userId) return await this.endUploadAvatar(uploadId)

    return await this.endUploadAccountAvatar(uploadId)
  }

  private async endUploadAvatar(id: string) {
    const { data } = await apiClients.finishUploadAvatar(id)
    return data
  }

  private async endUploadAccountAvatar(id: string) {
    const { data } = await apiAuth.finishUploadAvatar(id)
    return data
  }

  public async uploadProductsTemplate(
    file: any,
    getUrlReq: GetPresignedUrlReq,
    params: ProductTemplateParams,
    onProgress?: (p: any) => void
  ): Promise<any> {
    const { presignedUrl, uploadId } = await this.getLink(getUrlReq, params)

    await this.uploadFile(presignedUrl, file, onProgress)

    return await this.endUploadProductsTemplate(uploadId)
  }

  private async endUploadProductsTemplate(id: string) {
    const { data } = await apiProducts.finishUploadTemplate(id)
    return data
  }
}

export const presignedUploaderService = new PresignedUploaderService()
