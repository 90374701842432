import React from 'react'
import { Button, Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'
import type { ColumnsType } from 'antd/lib/table/interface'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { authSelectors } from '@/bus/auth'
import { PhoneOutline } from '@/assets/icons'
import { isActiveFilter } from '@/configs/configStatus'
// Local Dir
import styles from './styles.module.scss'
// Typing
import type { Client } from '@/bus/clients'
import { productionTypeTitlesConfig } from '@/configs/productions.configs'
import { getProductionStatusTitle } from '@/helpers/productionStatus'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { ReviewOutline } from '@/assets/icons/ReviewOutline'

export const useColumns = () => {
  const history = useHistory()
  const isAdmin = useSelector(authSelectors.isAdmin)

  const columns: ColumnsType<Client.Item> = [
    {
      title: 'ПІБ',
      dataIndex: ['clientInfo', 'name'],
      key: 'name',
      sorter: true,
      width: '20%',
      render: (_, value: Client.Item) => {
        return (
          <Link
            to={`${RouteEnum.Clients}/${value.id}`}
            className={styles.column__name}
          >
            {value.clientInfo.name}
          </Link>
        )
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Номер телефона',
      dataIndex: ['clientInfo', 'phoneNumber'],
      key: 'phoneNumber',
      className: styles.column__phone,
      width: 155,
      render: (value) => (
        <>
          <a className={styles.column__phone} href={`tel:${value}`}>
            <PhoneOutline
              style={{
                marginRight: 8,
              }}
            />
            {value}
          </a>
        </>
      ),
    },
    // {
    //   title: 'Статус',
    //   dataIndex: ['clientInfo', 'status'],
    //   key: 'status',
    //   filters: statusesFilter,
    //   render: (value) => {
    //     return <TagStatus statusId={value} />
    //   },
    // },
    // {
    //   title: 'Термін лікування',
    //   dataIndex: ['clientInfo', 'termOfTreatment'],
    //   key: 'termOfTreatment',
    //   render: (value) => {
    //     return <span>{value ? value : 'Не вказаний'}</span>
    //   },
    // },
    {
      title: 'Виробництво',
      dataIndex: 'production',
      key: 'production',
      render: (value) => (
        <div>
          {value ? (
            <>
              {' '}
              <div>{productionTypeTitlesConfig[value?.type]}</div>
              <div>{`(${getProductionStatusTitle(value)})`}</div>
            </>
          ) : (
            ''
          )}
        </div>
      ),
    },
    {
      title: 'Дата додавання',
      dataIndex: 'createDate',
      key: 'createDate',
      defaultSortOrder: 'descend',
      sorter: true,
      render: (value) => moment(value).format('DD.MM.YYYY'),
    },
    {
      dataIndex: 'id',
      key: 'action',
      align: 'right',
      render: (id) => (
        <Tooltip title="Переглянути профіль">
          <Button
            onClick={() => history.push(`${RouteEnum.Clients}/${id}`)}
            type="primary"
            className={styles.button}
          >
            <ReviewOutline />
          </Button>
        </Tooltip>
      ),
    },
  ]

  if (isAdmin) {
    columns.unshift({
      title: 'Клініка',
      dataIndex: ['clientInfo', 'clinicName'],
      key: 'clinicName',
      sorter: true,
      width: '15%',
      render: (_, value: Client.Item) => {
        if (!value.clientInfo.clinicName) {
          return 'Адміністратор'
        }

        return (
          <Link
            to={`${RouteEnum.Partners}/${value.clientInfo.partnerId}`}
            className={styles.column__name}
          >
            {value.clientInfo.clinicName}
          </Link>
        )
      },
    })
  }

  return columns
}
