import React from 'react'
import { Tag } from 'antd'
import {
  AuditOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarOutlined,
  ExperimentOutlined,
  HeartOutlined,
  InboxOutlined,
  SendOutlined,
  SmileOutlined,
  SyncOutlined,
} from '@ant-design/icons'

import { ClientStatusAlias } from '@/configs/BackendEnum/client-status-aliases.enum'

const configStatus = {
  [ClientStatusAlias.Hold]: {
    color: 'default',
    icon: <InboxOutlined />,
    text: 'Чернетка',
  },
  [ClientStatusAlias.Unseemly]: {
    color: 'processing',
    icon: <SyncOutlined />,
    text: 'Очікування',
  },
  [ClientStatusAlias.CreateSmileDesign]: {
    color: 'gold',
    icon: <ClockCircleOutlined />,
    text: 'Створення SmileDesign',
  },
  [ClientStatusAlias.SmileDesignIsReady]: {
    color: 'gold',
    icon: <SmileOutlined />,
    text: 'SmileDesign готовий',
  },
  [ClientStatusAlias.CTEvaluation]: {
    color: 'error',
    icon: <AuditOutlined />,
    text: 'Оцінка КТ',
  },
  [ClientStatusAlias.AwaitingAdvancePayment]: {
    color: 'processing',
    icon: <DollarOutlined />,
    text: 'Очікування авансового платежу',
  },
  [ClientStatusAlias.Production]: {
    color: 'error',
    icon: <ExperimentOutlined />,
    text: 'Виробництво',
  },
  [ClientStatusAlias.Sending]: {
    color: 'processing',
    icon: <SendOutlined />,
    text: 'Відправка',
  },
  [ClientStatusAlias.Treatment]: {
    color: 'gold',
    icon: <HeartOutlined />,
    text: 'Лікування',
  },
  [ClientStatusAlias.Completed]: {
    color: 'success',
    icon: <CheckCircleOutlined />,
    text: 'Завершено',
  },
}

type PropsTypes = {
  statusId: number | null
}

export const TagStatus: React.FC<PropsTypes> = ({ statusId }) => {
  if (!statusId) statusId = ClientStatusAlias.Hold

  return (
    <Tag
      icon={configStatus[statusId].icon}
      color={configStatus[statusId].color}
    >
      {configStatus[statusId].text}
    </Tag>
  )
}
