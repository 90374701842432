import React from 'react'
import Icon from '@ant-design/icons'

const StockOutlineSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg"  {...props}>
    <path d="M17.5 5C17.5 4.33696 17.2366 3.70107 16.7677 3.23223C16.2989 2.76339 15.663 2.5 15 2.5H4.99997C4.49663 2.50298 4.0059 2.65783 3.59201 2.94429C3.17813 3.23075 2.86035 3.63547 2.68026 4.10551C2.50017 4.57554 2.46616 5.08899 2.58267 5.57867C2.69919 6.06835 2.96081 6.51145 3.3333 6.85V15C3.3333 15.663 3.59669 16.2989 4.06554 16.7678C4.53438 17.2366 5.17026 17.5 5.8333 17.5H14.1666C14.8297 17.5 15.4656 17.2366 15.9344 16.7678C16.4032 16.2989 16.6666 15.663 16.6666 15V6.85C16.9271 6.61701 17.1359 6.332 17.2794 6.01334C17.423 5.69469 17.4981 5.34948 17.5 5ZM4.99997 4.16667H15C15.221 4.16667 15.4329 4.25446 15.5892 4.41074C15.7455 4.56702 15.8333 4.77899 15.8333 5C15.8333 5.22101 15.7455 5.43298 15.5892 5.58926C15.4329 5.74554 15.221 5.83333 15 5.83333H4.99997C4.77896 5.83333 4.56699 5.74554 4.41071 5.58926C4.25443 5.43298 4.16664 5.22101 4.16664 5C4.16664 4.77899 4.25443 4.56702 4.41071 4.41074C4.56699 4.25446 4.77896 4.16667 4.99997 4.16667ZM15 15C15 15.221 14.9122 15.433 14.7559 15.5893C14.5996 15.7455 14.3876 15.8333 14.1666 15.8333H5.8333C5.61229 15.8333 5.40033 15.7455 5.24405 15.5893C5.08777 15.433 4.99997 15.221 4.99997 15V7.5H15V15Z" />
    <path d="M11.775 10H8.225C7.82459 10 7.5 10.3246 7.5 10.725V10.9417C7.5 11.3421 7.82459 11.6667 8.225 11.6667H11.775C12.1754 11.6667 12.5 11.3421 12.5 10.9417V10.725C12.5 10.3246 12.1754 10 11.775 10Z" />
  </svg>
)

export const StockOutline = (props) => (
  <Icon component={StockOutlineSvg} {...props} />
)
