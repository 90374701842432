import { AxiosPromise } from 'axios'
import axios from '@/services/axios'

export const apiInteractiveNotifications = new (class Api {
  fetch(params: any): AxiosPromise<any> {
    return axios({
      url: '/interactive-notifications',
      method: 'get',
      params,
    })
  }
  count(params: any): AxiosPromise<number> {
    return axios({
      url: '/interactive-notifications/count',
      method: 'get',
      params,
    })
  }

  remove(id: number) {
    return axios({
      url: `/interactive-notifications/${id}`,
      method: 'delete',
    })
  }

  fastAnswer(id: number, content: string) {
    return axios({
      url: `/interactive-notifications/${id}/answer`,
      method: 'post',
      data: {
        content,
      },
    })
  }
})()
