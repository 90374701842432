import { apiInteractiveNotifications } from '@/bus/interactive-notifications/api'
import { apiOrders } from '@/bus/orders/api'
import { useEventsListener } from '@/hooks/useEventsListener'
import { useEffect, useState } from 'react'

export const useOrdersCount = () => {
  const [count, setCount] = useState(0)
  const [isLoading, setLoading] = useState(true)

  const load = async () => {
    try {
      setLoading(true)

      const { data } = await apiOrders.getCount()
      setCount(data)
    } catch (e) {
      setCount(0)
    } finally {
      setLoading(false)
    }
  }

  useEventsListener(
    'onUpdateOrders',
    () => {
      load()
    },
    []
  )

  useEffect(() => {
    load()
  }, [])

  return { count }
}
