import { useDispatch } from 'react-redux'
import { message } from 'antd'
import type { RcFile, UploadProps } from 'antd/lib/upload/interface'
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface'

import imageCompression from 'browser-image-compression'
// Local
import { getExtension } from '@/helpers/getExtension'
import { isJpgOrPng, isStl, isArchive, isMp4 } from '@/helpers/guardFiles'
import { clientFilesActions } from '@/bus/client-files'
import { presignedUploaderService } from '@/services/presigned-uploader.service'
import { apiClientFiles } from '@/bus/client-files/api'
import { eventsObserver } from '@/services/observable.service'

const regImageMimeType = /(png|jpg|jpeg)/gi

export const useFilesUpload = (clientId?: any) => {
  const dispatch = useDispatch()

  const beforeUploadPhoto = (
    file: RcFile
  ): ReturnType<UploadProps['beforeUpload']> => {
    if (!isJpgOrPng(file) && !isMp4(file)) {
      message.error('Ви можете завантажити лише JPG/PNG/MP4 файли!')
      return false
    }

    return true
  }

  const beforeUploadStl = (file: File): boolean => {
    if (!isStl(file)) {
      message.error('Ви можете завантажити лише файл STL!')
      return false
    }

    return true
  }

  const beforeUploadArchive = (file: File): boolean => {
    if (!isArchive(file)) {
      message.error('Ви можете завантажити лише архів типу ZIP/RAR!')
      return false
    }

    return true
  }

  const compressFile = async (file: any, mimetype: string, name: string) => {
    if (!mimetype.match(regImageMimeType)) return file
    const compressOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1980,
      useWebWorker: true,
    }

    const compressToBlob = await imageCompression(file, compressOptions)
    //@ts-ignore
    return new File([compressToBlob], name)
  }

  const requestV2 = async (
    options: RcCustomRequestOptions,
    tagId: number,
    _clientId = clientId,
    updateFileId?: number
  ) => {
    if (!_clientId) return message.warn('Не вказаний клієнт!')

    //@ts-ignore
    const mimetype = getExtension(options.file?.name)
    const filename = (options.file as any).name
    const hideLoadingMessage = message.loading(`Файл завантажується ...`, 0)

    const file = await compressFile(options.file, mimetype, filename)
    const id = new Date().getTime() + Math.random()

    const uploadedFile = await presignedUploaderService.upload(
      file,
      apiClientFiles.getPresignedUrl,
      {
        filename: filename,
        mimetype,
        tagId,
        userId: _clientId,
        updateFileId: updateFileId,
      },
      (p) => {
        eventsObserver.broadcast('onItemProgrress', {
          filename: filename,
          progress: Math.round((p.loaded * 100) / p.total),
          id: id,
        })
      }
    )

    eventsObserver.broadcast('onItemProgrress', {
      filename: filename,
      progress: 101,
      id: id,
    })

    hideLoadingMessage()

    if (options.onSuccess) options.onSuccess(uploadedFile, options.file as any)

    if (updateFileId)
      dispatch(clientFilesActions.updateImage({ file: uploadedFile }))
    else dispatch(clientFilesActions.saveNewFile({ file: uploadedFile, tagId }))
  }

  return {
    beforeUploadPhoto,
    beforeUploadStl,
    beforeUploadArchive,
    requestV2,
  }
}
