import { createSelector } from 'reselect'

import type { RootState } from '@/core/store/rootReducer'
import type { Account } from './namespace'

// Selector
// Memoization

export const isAuthenticate = (state: RootState) => state.auth.isAuthenticate
export const isInitialized = (state: RootState) => state.auth.isInitialized
export const getDataRestorePassword = (state: RootState) =>
  state.auth.dataRestorePassword
export const getCurrentAccount = (state: RootState) => state.auth.currentAccount

export const isClient = createSelector(
  getCurrentAccount,
  (currentAccount: Account.CurrentAccount) => currentAccount?.roleId === 1
)

export const isPartner = createSelector(
  getCurrentAccount,
  (currentAccount: Account.CurrentAccount) => currentAccount?.roleId === 2
)

export const isAdmin = createSelector(
  getCurrentAccount,
  (currentAccount: Account.CurrentAccount) => currentAccount?.roleId === 3
)

export const getWallet = (state: RootState) => {
  return state.auth.wallet
}

export const getSDDiagnosticPrice = createSelector(
  getCurrentAccount,
  (currentAccount: Account.CurrentAccount) => {
    if (currentAccount.roleId === 3) return 0
    const res = Number(currentAccount.info.SDDiagnosticsPrice)
    return isNaN(res) ? 0 : res
  }
)

export const isAccountInspe = (state: RootState) => {
  const _isAdmin = isAdmin(state)
  if (_isAdmin) return false

  const _isPartner = isPartner(state)
  if (!_isPartner) return true

  const account = getCurrentAccount(state)
  return account.info?.isInspe
}
