export enum ClientStatusAlias {
  Hold = 3, // Відкладено
  Unseemly = 5, // Неразобраное
  CreateSmileDesign = 10, // Створення SmileDesign
  SmileDesignIsReady = 20, // SmileDesign готовий
  CTEvaluation = 30, // Оцінка КТ
  AwaitingAdvancePayment = 40, // Очікування авансового платежу
  Production = 50, // Виробництво
  Sending = 60, // Відправка
  Treatment = 70, // Лікування
  Completed = 80, // Завершено
}
