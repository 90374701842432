import React from 'react'
import { Col, Row, Table } from 'antd'
// Typings
import { Client } from '@/bus/clients'
// Local
import { usePagination } from '@/hooks'
import { useColumns } from './useColumns'
import { useFetchData } from './useFetchData'
import { Partner } from '@/bus/partners'

export type Item = {
  id: number
  clientId: number
  partnerId: number
  rating: number
  comment: string
  clientName: string
  partnerName: string
}

type PropsTypes = {
  showDrawer: () => void
  dataSource: Item[]
  loading: boolean
  count: number
  fetchItems: (
    paramsClients: Client.ReqFetchItems,
    paramsPartners: Partner.ReqFetchItems
  ) => void
  className?: string
  searchString?: string
}

export const RatingTable: React.FC<PropsTypes> = (props: PropsTypes) => {
  const {
    dataSource,
    loading = false,
    count = 0,
    fetchItems,
    className,
    searchString,
  } = props

  const { handleTableChange } = useFetchData(fetchItems, searchString)

  const pagination = usePagination(count)
  const columns = useColumns()

  return (
    <Row justify="center">
      <Col span={24}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={dataSource}
          onChange={handleTableChange}
          pagination={{ ...pagination, position: ['bottomCenter'] }}
          scroll={{ x: 1100 }}
          rowKey={(item: Item): string => item.id.toString()}
          className={className}
        />
      </Col>
    </Row>
  )
}
