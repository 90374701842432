import React, { Fragment, lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
// Local
import { RouteEnum } from '@/configs/RouteEnum'
import { AuthLayout, MainLayout, SharedLayout } from '@/layouts'
import { PageLoading } from '@/components'
// Guards
import {
  AdminGuard,
  AuthGuard,
  GuestGuard,
  PartnerGuard,
} from '@/components/GuardsAuth'

type Routes = {
  exact?: boolean
  path?: string | string[]
  guard?: any
  layout?: any
  component?: any
  routes?: Routes
}[]

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<PageLoading />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

const routes: Routes = [
  {
    exact: true,
    path: RouteEnum.Error404,
    component: lazy(() => import('@/views/pages/error-404')),
  },
  {
    exact: true,
    path: RouteEnum.Folders,
    layout: SharedLayout,
    component: lazy(() => import('@/views/folders')),
  },
  {
    exact: true,
    path: RouteEnum.StlViewer,
    component: lazy(() => import('@/views/stl-viewer')),
  },
  {
    guard: GuestGuard,
    path: RouteEnum.Auth,
    layout: AuthLayout,
    routes: [
      {
        exact: true,
        path: RouteEnum.Auth,
        guard: GuestGuard,
        component: () => <Redirect to={RouteEnum.SignIn} />,
      },
      {
        exact: true,
        path: RouteEnum.SignIn,
        guard: GuestGuard,
        component: lazy(() => import('@/views/auth/sign-in')),
      },
      {
        exact: true,
        path: RouteEnum.SignUp,
        guard: GuestGuard,
        component: lazy(() => import('@/views/auth/sign-up')),
      },
      {
        exact: true,
        path: RouteEnum.SignUpCode,
        guard: GuestGuard,
        component: lazy(() => import('@/views/auth/sign-up/FinishSignUpView')),
      },
      {
        exact: true,
        path: RouteEnum.ForgotPassword,
        guard: GuestGuard,
        component: lazy(() => import('@/views/auth/reset-password/EmailForm')),
      },
      {
        exact: true,
        path: RouteEnum.ForgotPasswordCode,
        guard: GuestGuard,
        component: lazy(() => import('@/views/auth/reset-password/CodeForm')),
      },
      {
        exact: true,
        path: RouteEnum.ForgotPasswordPassword,
        guard: GuestGuard,
        component: lazy(
          () => import('@/views/auth/reset-password/PasswordForm')
        ),
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: () => <Redirect to={RouteEnum.SignIn} />,
      },
      {
        exact: true,
        path: RouteEnum.App,
        component: () => <Redirect to={RouteEnum.Clients} />,
        // component: lazy(() => import('@/views/home')),
      },
      {
        exact: true,
        path: RouteEnum.AccountSettings,
        component: lazy(() => import('@/views/account-settings')),
      },
      {
        exact: true,
        path: RouteEnum.Partners,
        guard: AdminGuard,
        component: lazy(() => import('@/views/partners')),
      },
      {
        exact: true,
        path: RouteEnum.PartnerDetail,
        guard: AdminGuard,
        component: lazy(() => import('@/views/partners-detail')),
      },
      {
        exact: true,
        path: RouteEnum.Clients,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/clients')),
      },
      {
        exact: true,
        path: RouteEnum.Stock,
        component: lazy(() => import('@/views/stock')),
      },
      {
        exact: true,
        path: RouteEnum.Rating,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/rating')),
      },
      {
        exact: true,
        path: RouteEnum.ClientDetail,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/clients-detail')),
      },
      {
        exact: true,
        path: RouteEnum.PointsHistory,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/points-history')),
      },
      {
        exact: true,
        path: RouteEnum.AlignersStatistics,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/aligners-stat')),
      },
      {
        exact: true,
        path: RouteEnum.TransactionHistory,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/transaction-history')),
      },
      {
        exact: true,
        path: RouteEnum.ChangesHistory,
        guard: AdminGuard,
        component: lazy(() => import('@/views/packages-history')),
      },
      {
        exact: true,
        path: RouteEnum.StatisticsMenu,
        guard: AdminGuard,
        component: lazy(() => import('@/views/statistics-menu')),
      },
      {
        exact: true,
        path: RouteEnum.ClientStatistics,
        guard: AdminGuard,
        component: lazy(() => import('@/views/client-statistics')),
      },
      {
        exact: true,
        path: RouteEnum.PaymentsMenu,
        guard: AdminGuard,
        component: lazy(() => import('@/views/payments-menu')),
      },
      {
        exact: true,
        path: RouteEnum.ProductsList,
        guard: AdminGuard,
        component: lazy(() => import('@/views/products-list')),
      },
      {
        exact: true,
        path: RouteEnum.Productions,
        guard: AdminGuard,
        component: lazy(() => import('@/views/productions')),
      },
      {
        exact: true,
        path: RouteEnum.InteractionNotifications,
        guard: AdminGuard,
        component: lazy(() => import('@/views/interactive-notifications')),
      },
      {
        exact: true,
        path: RouteEnum.Orders,
        guard: AdminGuard,
        component: lazy(() => import('@/views/orders')),
      },
      {
        exact: true,
        path: RouteEnum.Settings,
        guard: AdminGuard,
        component: lazy(() => import('@/views/settings')),
      },
      {
        exact: true,
        path: RouteEnum.PartnerPricing,
        guard: PartnerGuard,
        component: lazy(() => import('@/views/partner-pricing')),
      },
      {
        component: () => <Redirect to={RouteEnum.Error404} />,
      },
    ],
  },
]

export default routes
