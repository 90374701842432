import axiosInstance from '@/services/axios'
import { Orders } from './namespace'

export const apiOrders = new (class Api {
  createOrder(payload: Orders.ICreateOrderPayload) {
    return axiosInstance.post('/orders', payload)
  }

  fetchList(params: Orders.ReqFetchList) {
    return axiosInstance.get('/orders', { params })
  }

  getCount() {
    return axiosInstance.get('/orders/count')
  }

  updateStatus(id: number, status: Orders.OrderStatus) {
    return axiosInstance.patch(`/orders/${id}/status`, { status })
  }
})()
